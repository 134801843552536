<template>
    <loader v-bind="{ loading }" text="Loading Notifications">
        <columns>
            <column>
                <page-heading heading="Notifications" />
            </column>
        </columns>
        <columns>
            <column>
                <div class="index-rows">
                    <div class="box is-marginless" v-for="notification in notifications.data" :key="notification.id">
                        <columns>
                            <column>
                                <router-link class="mr-2" :to="{
                                    name: 'notification-manager',
                                    params: {
                                        notification: notification.id
                                    }
                                }">
                                    {{ notification.name }}
                                </router-link>
                                <small class="has-text-grey">{{ notification.created_at | asCalendar }}</small>
                            </column>
                        </columns>
                    </div>
                </div>

                <pager 
                    v-if="notifications.data.length" 
                    :pageable="notifications" 
                    context="Notification" 
                    jump-controls
                    @nav="goToPage" />

                <no-items-to-display :item-count="notifications.data.length"
                    heading="There are no notifications available for display." />

            </column>
        </columns>
    </loader>
</template>
<script>
import { get } from '@/api/request'
export default {

    data: () => ({
        loading: true,
        filters: {
            search: ''
        },
        notifications: {
            data: []
        }
    }),

    created() {
        if (this.$route.query) {
            this.filters.search = this.$route.query.search
        }
        this.loadNotifications()
    },

    methods: {
        loadNotifications() {
            get('v1' + this.$route.fullPath, ({ data }) => {
                this.notifications = data
                this.loading = false
            }, () => {
                this.loading = false
            }, { params: this.filters })
        },
        goToPage(path) {
            this.$router.push(path)
        },
        runSearch() {
            this.$router.push({
                name: 'notifications',
                query: this.filters
            });
        },
        clearFilters() {
            this.filters.search = ''
            this.$router.push({
                name: 'notifications',
            });
        }
    },

    watch: {
        '$route': 'loadNotifications'
    }

}
</script>